import api from './index';

class CategoryService {
    async find() {
        return api.get(`/categories`);
    }

    async create(data) {
        return api.post(`/categories`, data);
    }

    async update(data) {
        return api.put(`/categories`, data);
    }

    async delete(id) {
        return api.delete(`/categories/${id}`);
    }

    async deleteMany(selectedCategories) {
        return api.post(`/categories/deleteMany`, selectedCategories);
    }
}

export default new CategoryService();